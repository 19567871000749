import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export default function PrimaryButton({className = '', disabled, loading, children, ...props}) {
    return (
        <button
            {...props}
            className={
                `items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 ${
                    (disabled || loading) && 'opacity-25'
                } ` + className
            }
            disabled={disabled || loading}
        >
            {loading ? <FontAwesomeIcon className={'mr-2'} icon={faSpinner} spin={true}/> : <></>}
            {children}
        </button>
    );
}
