import React, {useEffect, useState} from 'react';
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';

export default function SectionClients() {

    const clients = [
        {name: 'Anker', thumb: '/c/Anker.svg'},
        {name: 'Bank of Sharjah', thumb: '/c/Bank of Sharjah Logo-01.svg'},
        {name: 'Saudi Electricity Company', thumb: '/c/Saudi_Electricity_Company-01.svg'},
        {name: 'DCCI', thumb: '/c/DCCI logo.svg'},
        {name: 'Al Fardan Exchange', thumb: '/c/Al fardan exchange-01.svg'},
        {name: 'Dunlop', thumb: '/c/Dunlop-01.svg'},
        {name: 'UAE Pro League', thumb: '/c/jkd2g51643234984.png'},
        {name: 'Michael Kors', thumb: '/c/michael_kors-01.svg'},
        {name: 'Siemens', thumb: '/c/siemens.svg'},
        {name: 'Bosch Logo', thumb: '/c/Bosch Logo 23.svg'},
        {name: 'Hasbro', thumb: '/c/Hasbro.svg'},
        {name: 'Fidelity', thumb: '/c/fidelity.svg  '},
        {name: 'Mentos', thumb: '/c/mentos-logo-01.svg'},
        {name: 'Falken', thumb: '/c/Falken Logo-01.svg'},
        {name: 'Iffco', thumb: '/c/Iffco Logo-01.svg'},
        {name: 'BBAC', thumb: '/c/bbac.png'},
        {name: 'Lacnor', thumb: '/c/Lacnor Logo.svg'},
        {name: 'NorthwestBus', thumb: '/c/Northwestbuse Logo.svg'},
        {name: 'Protector and Gamble', thumb: '/c/Procter_and_Gamble-01.svg'},
        {name: 'RedBull', thumb: '/c/redbull-01.svg'},
        {name: 'GMG', thumb: '/c/gmg.svg'},
    ]

    const responsive = {
        0: {items: 2},
        700: {items: 3},
        1024: {items: 5},
        1400: {items: 6},
    };

    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        const _ = () => {
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', _)
        return () => window.removeEventListener('scroll', _)
    }, []);

    return <>
        <div className={'container relative mx-auto flex min-h-screen items-center flex-wrap py-[20vh]'}>

            <div className={'w-full lg:px-0 px-5 -mt-96 xl:px-10'} style={{transform: "translateY(" + (Math.max(0, (window.scrollY/3 - window.innerHeight) * .95)) + "px)"}}>
                <div className={'relative'}>
                    <h2 className={'sh text-end'}>
                        <span className={'relative z-10 text-sky-700 font-black'}>27 years</span> of success
                    </h2>
                    <p className={'sd max-w-3xl text-start text-6xl font-medium'}>
                       Success isn’t just about impressive campaigns—it’s about true partnerships. Together with our partners, we’ve created impactful work, and we’re just getting started.
                    </p>
                </div>
            </div>

            <div className={'w-full flex items-center invert dark:invert-0 h-40'}>
                <AliceCarousel
                    infinite
                    responsive={responsive}
                    touchTracking
                    disableButtonsControls
                    autoPlay
                    mouseTracking
                    keyboardNavigation={false}
                    controlsStrategy={'alternate'}
                    autoPlayInterval={3000}
                    animationDuration={500}
                    items={clients.map(c =>
                        <div className={'w-full text-center h-full'}>
                            <img alt={c.name} src={c.thumb} role={'presentation'}
                                 className={'px-10 mx-auto'}/></div>)
                    }/>
            </div>

        </div>
    </>
}
