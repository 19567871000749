import React, {useContext, useEffect, useRef} from 'react';
import SectionOurWork from "../components/SectionOurWork";
import SectionCareers from "../components/SectionCareers";
import SectionIntroduction from "../components/SectionIntroduction";
import SectionClients from "../components/SectionClients";
import SectionFooter from "../components/SectionFooter";
import {AppContext} from "../context/AppContext";
import SectionContact from "../components/SectionContact";
import SectionNews from "../components/SectionNews";
import SectionShowreel from "../components/SectionShowreel";
import SectionAbout from "../components/SectionAbout";
import {Helmet} from "react-helmet";
import SectionPeople from "../components/SectionPeople";
import {Transition} from "@headlessui/react";


export default function App() {

    const {setShowNavbar, showNavbar} = useContext(AppContext)
    const ref = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        if (window.scrollY > videoRef.current?.clientHeight) {
            setShowNavbar(true)
        }
    }, [setShowNavbar])


    return <div className={''} id={'ro'}>

        <Helmet>
            <title>{process.env.REACT_APP_TITLE}</title>
            <meta name={'description'} content={process.env.REACT_APP_DESCRIPTION}/>
        </Helmet>

        <div ref={ref} className={'mx-auto -mt-36 bg-blue-200/10 overflow-hidden'}>

            <div id="introduction"
                 className={'relative min-h-screen bg-sky-950/30'}>
                <SectionIntroduction/>
            </div>

            <div ref={videoRef} id={'showreel'} className={'bg-sky-700/10 relative z-50 min-h-screen max-h-screen'}>
                <SectionShowreel/>
            </div>

            <div id="about" className={'relative min-h-screen'}>
                <div id={'stripes'} className={'absolute z-50 w-[110vw] -left-7 top-0 rounded-3xl opacity-[.02]'}/>
                <SectionAbout/>
            </div>

            <div id="work" className={'min-h-screen shadow-[0_0_200px_170px_rgba(0,0,0,.3)] py-96 overflow-hidden relative z-30 bg-sky-950/30'}>
                <div id={'stripes'} className={'absolute w-screen !h-full z-0 top-0 left-0 opacity-[.015]'}/>
                <SectionOurWork _ref={ref}/>
            </div>

            <div id="clients"
                 className={'bg-white text-black relative min-h-screen'}>
                <SectionClients/>
            </div>

            <div id="news"
                 className={'min-h-screen bg-white flex items-center justify-center relative py-40 z-10'}>
                <SectionNews/>
            </div>

            <div id="careers"
                 className={'min-h-screen relative z-10'}>
                <SectionCareers/>
            </div>

            <div id="people"
                 className={'min-h-screen relative z-10'}>
                <SectionPeople/>
            </div>

            <div className={'relative'} id={'contact'}>
                <SectionContact/>
            </div>

            <div id="footer"
                 className={'relative'}>
                <SectionFooter/>
            </div>
        </div>
    </div>

}