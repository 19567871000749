import React, {useEffect} from 'react';
import {BarLoader} from "react-spinners";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlayCircle} from "@fortawesome/free-solid-svg-icons";


export default function MediaGallery({initMedia = [], setCoverImage}) {

    const [loading, setLoading] = React.useState(false);
    const [media, setMedia] = React.useState(initMedia);
    const [selected, setSelected] = React.useState(initMedia[0] ?? null);
    const mainImageRef = React.useRef(null);

    const setMainMedia = (media) => {
        setLoading(true)
        setCoverImage(media)
        setSelected(media)
    }

    return <>
        <div className={'w-full z-10 shadow-[0_0_200px_170px_rgba(0,17,27,.3)] bg-neutral-950 group relative'}>

            <div className={'aspect-video relative'}>
                {loading
                    && <div className={'absolute z-10 w-full h-full flex items-center justify-center'}>
                        <BarLoader color={'white'}/></div>}

                <div className={'z-0 '}>
                    {selected.type === 'photo' &&

                        <img style={{filter: window.scrollY > 1000 ? 'blur(' + Math.max(0, ((window.scrollY - 1000) / 70)) + 'px)' : ''}}
                             ref={mainImageRef} src={selected?.path?.full}
                             onLoad={() => setLoading(false)}
                             alt={'.'}
                             className={'z-10 relative w-full h-full object-cover -indent-96 duration-100 ' + (loading ? 'blur-3xl' : '')}
                        />
                    }
                    {selected.type === 'video' && <div className={'h-screen'}>
                        <video className={'absolute z-0 h-max w-full bg-black'} autoPlay controls={true} src={selected.path.src}/>
                    </div>}

                </div>
                <div
                    className={'flex opacity-10 z-20 -mt-36 sticky bottom-0  mx-auto mb-10 hover:opacity-100 transition-all justify-center h-min items-center'}>
                    {media.length > 1 ? media.map((media, index) => <div key={index} className={'w-32'}>
                        <div className={'w-full p-1 aspect-square'}>
                            {media.type === 'photo' ?
                                <img src={media.path.square_sm}
                                     className={'h-full w-full object-cover duration-500 hover:-mt-1 hover:ring-1 dark:ring-white block transition-all shadow hover:shadow-lg shadow-black/5 ' +
                                         'hover:shadow-black/15 z-10 cursor-pointer' +
                                         (media === selected ? ' rounded-3xl !ring-4 ' : ' rounded-sm')}
                                     style={{textIndent: -999999}}
                                     onClick={() => media !== selected && setMainMedia(media)}
                                     alt={'.'}
                                />
                                : <div className={'w-full cursor-pointer h-full bg-white/20 relative'}
                                       onClick={() => media !== selected && setSelected(media)}>
                                    <video
                                        className={'object-cover bg-black/20 flex items-center justify-center h-full cursor-pointer hover:text-white transition-all rounded-md'}
                                        src={'/showreel.mp4'} autoPlay={false} loop={false} muted={true}
                                    >
                                    </video>
                                    <FontAwesomeIcon icon={faPlayCircle}
                                                     className={'w-10 mx-auto absolute top-0 bottom-0 left-0 right-0 h-full text-white z-10'}/>
                                </div>}
                        </div>
                    </div>) : <></>}
                </div>


            </div>

        </div>
    </>
}