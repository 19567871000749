import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {api} from "../../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import PageContainer from "../../components/PageContainer";
import MediaGallery from "../../components/MediaGallery";
import {Helmet} from "react-helmet";
import {BarLoader} from "react-spinners";

export default function WorkSingle() {

    const [galleryShowing, setGalleryShowing] = useState(true);

    const [media, setMedia] = useState({});

    const {id} = useParams();

    const [loadedProject, setLoadedProject] = React.useState({
        id: null,
        title: null,
        description: <></>,
        media: [],
    });

    const [coverImage, setCoverImage] = useState({});
    const [loadedSuccessfully, setLoadedSuccessfully] = useState(true);
    const [loading, setLoading] = React.useState(true);

    const [scrollY, setScrollY] = useState(window.scrollY);

    useEffect(() => {
        //setTimeout(() => window.scrollTo({top: 0, behavior: 'smooth'}), 50)
        const _ = () => {
            setScrollY(window.scrollY);
        }
        window.addEventListener("scroll", _)
        window.addEventListener("resize", _)
        return () => {
            window.removeEventListener("scroll", _)
            window.removeEventListener("resize", _)
        }
    }, []);


    useEffect(() => {

        setLoading(true)

        api.get('/work/front/' + id).then(response => {
            if (response.data) {
                setLoadedProject(response.data);
                setLoadedSuccessfully(true)
            } else {
                setLoadedSuccessfully(false)
            }
        }).catch(e => {
            setLoadedSuccessfully(false)
        }).finally(() => setLoading(false))

    }, [id]);


    return <div className={''}>
        <Helmet>
            <title>{loadedProject.title}</title>
            <meta name={'description'} content={loadedProject.description}/>
        </Helmet>
        {loading
            ? <div className={'relative z-10 flex items-center justify-center h-[calc(100vh-250px)]'}>
                <BarLoader color={'#09e'}/>
            </div>

            : loadedSuccessfully
                ? <PageContainer title={loadedProject.title}>
                    <div className={'px-5 pr-10'} style={{
                        transform: "translateY("+Math.max(0, ((window.scrollY*.9) - (window.innerHeight/200))) +"px)",
                    }}>
                        <div className={'mix-blend-exclusion z-0 container mx-auto mt-[10vh]'}>
                            <h1 className={'pr-5 drop-shadow lg:text-9xl text-7xl font-bold absolute'}
                                style={{
                                    transform: "translateY(" + Math.min(-100, -((window.scrollY * .2) - (window.innerHeight / 200))) + "px)",
                                }}>
                                {loadedProject.title}
                            </h1>
                        </div>
                        <div className={'ab overflow-hidden container mx-auto duration-2000 ease-out z-10'} style={{
                            opacity: window.scrollY > (window.innerHeight * .33) ? 0 : 1,
                            transform: "translateY(" + Math.min(30, -((window.scrollY * .2) - (window.innerHeight / 200))) + "px)",
                        }}>
                            <article className={'text-sm relative z-20'}
                                     dangerouslySetInnerHTML={{__html: loadedProject.content}}/>

                            <Link to={''} className={'block text-end text-4xl font-thin transition-all opacity-20 hover:opacity-100 group'}>
                                <>Nexxt Project</>
                                <FontAwesomeIcon className={'text-4xl duration-100 font-light group-hover:-mr-2 group-hover:ml-2'}
                                                 icon={faAngleDoubleRight}/>
                            </Link>
                        </div>
                    </div>
                    <div className={''}>
                        {loadedProject.media?.length && <MediaGallery setCoverImage={setCoverImage} initMedia={loadedProject.media ?? []}/>}
                    </div>

                    {coverImage && <img src={coverImage?.path?.full}
                                        onLoad={() => setLoading(false)}
                                        alt={'.'}
                                        className={'z-0 top-0 fixed scale-[3.8] left-0 duration-2000 ease-out w-screen h-screen blur-sm opacity-40 object-cover -indent-96'}
                                        style={{top: -Math.max(0, (window.scrollY * .2))}}/>}
                </PageContainer>
                : <div className={'select-none flex items-center justify-center min-h-[80vh]'}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <small className={'mt-1 mx-2'}>Could not load this item</small>
                </div>
        }
    </div>
}