import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import PageContainer from "../../components/PageContainer";

export default function CareersSingle() {
    const {id} = useParams();
    const [loadedCareer, setLoadedCareer] = useState({
        id: null,
        title: null,
        description: null,
    });

    useEffect(() => {
    }, []);

    return <PageContainer title={'Careers ' + (loadedCareer.title ?? '')}>

        <article>
            {id}
            <div className={'my-20'}>
                <h2 className={'font-black mb-2'}>Job Description</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias eaque eos eum facere facilis ipsam iure, libero, magni,
                    maxime neque quibusdam soluta. Cumque modi mollitia recusandae sequi? Doloribus ducimus, velit!
                </p>
            </div>

            <div className={'my-20'}>
                <h2>Responsibilities</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad autem commodi cumque deleniti distinctio dolor dolore
                    eligendi error excepturi facilis incidunt, molestias officia perspiciatis porro quidem quos reiciendis temporibus
                    voluptatem?
                </p>
            </div>
        </article>

    </PageContainer>
}