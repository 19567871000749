import React, {useEffect, useState} from 'react';


export const AboutBlock = ({className, offsetTop, title, children}) => <div
    className={'4xl:leading-none p-10 relative' +
        (className ? ' ' + className : '')}
    style={{marginTop: -offsetTop}}>
    <h2 className={'inline text-4xl 2xl:text-5xl lg:text-6xl mt-0 mb-3 select-none font-bold'}>{title}</h2>
    <div className={'inline py-2 pl-2 text-lg lg:text-xl font-thin 4xl:text-2xl'}>{children}</div>
</div>

export default function SectionAbout() {
    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        const _ = () => {
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', _)
        return () => window.removeEventListener('scroll', _)
    }, []);
    return <div className={'overflow-hidden'}>
        <div className={'min-h-[100vh] content-start container mx-auto'}
             style={{transform: "translateY(" + (Math.max(0, (window.scrollY - window.innerHeight) * .25)) + "px)"}}>
            <h2 className={'sh text-end'}>About us</h2>

            <div className={'flex flex-wrap'}>
                <AboutBlock
                    title={'history'}
                    className={'xl:w-full 2xl:w-1/2 text-justify animate-extraSlowBounce w-full'}>
                    Founded in 1997, we started as AGA – Arabian Gulf Advertising & Marketing, a full-service agency, dedicated to help
                    brands create memorable campaigns in the Middle East through creative bravery, strategic planning, media solutions,
                    PR,
                    branding and physical experiences. Through perseverance and ambition, we grew out of Dubai and became present across
                    the
                    region
                    in Lebanon, Jordan, Egypt and KSA. Our achievements led to us signing an affiliation agreement with ADK, Japan’s
                    third
                    largest agency Network with 50 offices around the world, and thus our new journey as AGA-ADK began. Today, we are
                    part of a
                    larger holding company known as The Network Communication Group - TNCG that provides holistic integrated marketing
                    communication solutions in the MENA region, all under one roof.
                </AboutBlock>
                <div className={'w-full flex justify-start items-start flex-wrap'}>
                    <AboutBlock className={'w-full text-justify xl:w-1/2'}
                                title={'mission'}>
                        We exist to create meaningful connections between brands and consumers in an ever evolving world driven by mobile
                        interaction and physical experiences.
                    </AboutBlock>
                    <AboutBlock
                        className={'w-full text-justify xl:w-1/2'}
                        title={'vision'}>
                        We strive to deliver evermore memorable campaigns to our brand partners by bringing forward fully integrated
                        marketing communication solutions that go beyond the now.
                    </AboutBlock>
                </div>
            </div>
        </div>
        <div id="stripes" className={'absolute z-20 opacity-[.03] h-[100vw] w-screen -top-0 left-0 rotate-90'}/>
    </div>
}