import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBasketShopping, faBoxesStacked, faCubes, faFileImage, faStar} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export const CareerBlock = ({career}) => <>
    <Link
        to={'/careers/1'}
        className={'border-white flex items-center flex-wrap justify-between hover:scale-[1.02] px-5 hover:dark:bg-white/10 duration-300 h-full ' +
            'cursor-pointer dark:bg-neutral-950/10 group hover:shadow-xl hover:text-white dark:text-white ' +
            'text-neutral-600 hover:bg-slate-800 p-2 sm:p-5 xl:px-7'}>
        <h3 className={'font-bold text-2xl w-full'}>{career.title}</h3>
        <div className={'w-1/12 transition-all group-hover:scale-110'}>
            <FontAwesomeIcon icon={career.icon} className={'px-1 w-full text-xl'}/>
        </div>
        <div className={'w-11/12 py-5'}>
            <p className={'font-thin px-7 text-sm line-clamp-2'} title={career.description}>
                {career.description}
            </p>
        </div>
    </Link>
</>

export default function SectionCareers() {
    const [careersList, setCareersList] = useState([]);

    useEffect(() => {
        setCareersList([
            {
                title: 'Web Developer',
                description: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sit amet tincidunt nibh... ',
                icon: faBoxesStacked
            },
            {
                title: 'Some position',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sit amet tincidunt nibh. Praesent tempor nisl id diam varius vehicula eu et nulla. Phasellus in urna ... ',
                icon: faCubes
            },
            {
                title: 'Marketing',
                description: 'consectetur adipiscing elit. Etiam sit amet tincidunt nibh. Praesent tempor nisl id diam varius vehicula eu et nulla. Phasellus in urna',
                icon: faStar
            },
            {
                title: 'Sales',
                description: 'eu et nulla. Phasellus in urna',
                icon: faBasketShopping
            },
            {
                title: 'Graphic designer',
                description: 'varius vehicula eu et n ertretin urna',
                icon: faFileImage
            },
        ])
    }, []);
    return <div className={'relative h-full py-52 xl:py-20'}>
        <article className={'mx-auto flex flex-wrap items-stretch justify-center'}>
            <div className={'select-none xl:text-2xl h-full '}>
                <div className={'flex flex-wrap h-full container  mx-auto items-center justify-around min-h-screen'}>
                    <div
                        className={'px-5 w-full xl:w-1/2 text-white content-center'}>
                        <h2 className={'mb-0 sh w-full xl:!text-end '}>
                            <span className={'font-light'}>Career</span>
                        </h2>
                        <hr className={'max-w-sm xl:mr-0 mx-auto my-10 border-black dark:border-sky-600 ml-auto'}/>
                        <div
                            className={'xl:text-end text-lg xl:max-w-xl text-justify mx-auto dark:text-white text-black xl:float-right overflow-hidden'}>
                            <div className={'font-thin'}>
                                A team fueled by curiosity, creativity, and a love for collaboration. Our diverse workspace is a launchpad
                                for campaigns built on teamwork across all departments. With a flexible operating structure, you’ll gain
                                exposure to the full spectrum of the industry. If you’re looking for a place where you can grow, get
                                inspired, and take your ideas to new heights—drop us a line.
                            </div>
                            <p className={'font-thin my-28'}>&mdash; So, if you’re an ambitious individual who thrives within a team and
                                looks for constant progress, you know what to do.
                            </p>
                        </div>
                    </div>

                    <div className={'xl:w-1/2 px-5 v'}>
                        <div className={'maxs-h-[calc(100vh-400px)] overflow-y-auto overflow-hidden flex flex-wrap'}>{
                            careersList?.map(career =>
                                <div key={career.title + career.description}
                                     className={'w-full lg:w-1/2 2xl:w-1/2 p-1'}>
                                    <CareerBlock ik={career.title} career={career}/>
                                </div>)
                        }
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
}
