import React, {useEffect, useState} from 'react';
import PageContainer from "../../components/PageContainer";
import {useParams} from "react-router-dom";
import {api} from "../../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export default function NewsSingle({title}) {
    const [isLoading, setIsLoading] = useState(true);
    const {slug} = useParams();

    const [loadedArticle, setLoadedArticle] = useState({
        id: null,
        title: null,
        body: null,
    });

    useEffect(() => {
        api.get('/news/' + slug).then(response => {
            setLoadedArticle(response.data);
        }).finally(() => setIsLoading(false));
    }, []);
    return isLoading ?
        <div className={'gap-x-10 font-thin text-3xl h-[80vh] select-none animate-pulse w-screen flex items-center justify-center'}>
            <FontAwesomeIcon className={''} icon={faSpinner} spin={true}/>
            <span>Loading article...</span>
        </div> :
        <PageContainer title={loadedArticle.title ?? 'Loading article'}>
            <div className={'container mx-auto pt-[30vh]'}>
                <div className={'mb-20'}>
                    <h1 className={'text-8xl font-bold'}>{loadedArticle.title}</h1>
                    <small className={'ml-1 font-serif text-sm mb-5 inline-block'}>{(new Date(loadedArticle.created_at)).toLocaleDateString()}</small>
                </div>
                <div>{loadedArticle.body}</div>
            </div>
        </PageContainer>
}