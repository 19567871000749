import React, {useEffect, useRef, useState} from 'react';
import {Transition} from "@headlessui/react";

export default function SectionIntroduction() {

    const [scrollY, setScrollY] = useState(window.scrollY);
    const videoRef = useRef()
    const [opacity, setOpacity] = useState(1);
    const intervalRef = useRef(null);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {

        if (videoRef.current) {
            videoRef.current.src = '/showreel.mp4'
            const playPromise = videoRef.current?.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    videoRef.current.playbackRate = .7
                }).catch(() => {
                });
            }
        }

        const updateScrollY = () => {
            setScrollY(window.scrollY)
        }

        const randomFlicker = () => {
            const randomOpacity = Math.random() - .01;
            setOpacity(randomOpacity);
        };

        intervalRef.current = setInterval(randomFlicker, 100);

        // Used for Transition animation
        setLoaded(true)

        window.addEventListener('scroll', updateScrollY)
        return () => {
            clearInterval(intervalRef.current)
            window.removeEventListener('scroll', updateScrollY)
        }
    }, []);

    return <div
        style={{
            transform: "translateY(" + window.scrollY * .4 + "px)"
        }}
        className={'min-h-screen flex items-center bg-sky-950/5 relative z-0 w-full overflow-hidden'}>
        <div className={'relative z-30 w-full'}>
            <div className={'h-full z-30 flex items-center'}>
                <div className={'absolute w-full px-3 lg:px-10'}>
                    <div className={'mt-60 text-start font-light block text-white px-3 xl:px-0'}>
                        <Transition show={!!loaded}
                                    className={'duration-1000 relative'}
                                    enterFrom={'top-[10vh] opacity-0'}
                                    enterTo={'top-0 opacity-100'}
                                    leaveFrom={'top-0 opacity-100'}
                                    leaveTo={'top-[10vh] opacity-0'}
                        >
                            <h2 className={'xl:max-w-[60vw] leading-[.7] sm:leading-[.7] md:leading-[.6] xl:leading-[.6] md:break-after-all lowercase md:hyphens-none hyphens-auto text-5xl sm:text-7xl md:text-8xl break-words xl:text-9xl'}>
                                <span className={''}><span className={'font-light '}>An independent</span> marketing
                                    communications <span
                                        className={'font-light'}>
                                        <span style={{opacity: opacity}} className={'neon-sign'}><span
                                            className={'neon-text'}>and</span>
                                        </span>
                                    </span> creative agency</span>
                            </h2>
                        </Transition>
                        <div
                            className={'my-10 duration-2000 border-0 h-[1px] glow rounded ease-out  bg-sky-500/80'}
                            style={{
                                width: Math.max(0, ((-(-window.scrollY + (window.innerHeight) / 20) * 2)) * .13) + '%',
                                maxWidth: '100%',
                            }}/>
                        <Transition show={!!loaded}
                                    className={'duration-500 relative delay-500'}
                                    enterFrom={'top-[10vh] opacity-0'}
                                    enterTo={'top-0 opacity-100'}
                                    leaveFrom={'top-0 opacity-100'}
                                    leaveTo={'top-[10vh] opacity-0'}
                        >
                            <p className={'my-10 drop-shadow text-justify max-w-7xl text-sm md:text-md xl:text-lg'}>
                                We're all about fresh ideas, and we’re not shy about chasing them down. To us, the universe is made of
                                stories—and
                                we’re here to help yours get noticed. Working side by side with our brand partners, we create campaigns
                                that
                                don’t
                                just connect with audiences but make them lean in and stay a while.
                            </p>
                        </Transition>


                    </div>
                </div>
            </div>
        </div>
        <div id="stripes" className={'absolute z-20 opacity-[.03] h-screen w-screen -top-0 left-0'}/>
        <div className={'w-screen z-20 absolute top-0 left-0 h-screen'}></div>
        <video ref={videoRef}
               className={'absolute top-0 z-10 h-screen w-screen object-cover invert opacity-10 grayscale scale-125 blur-2xl'} autoPlay
               loop
               muted/>
    </div>
}
