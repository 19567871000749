import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {api} from "../index";
import {BarLoader} from "react-spinners";

export const ProjectBlock = ({className, project, link}) => {


    const linkClassName = 'group block z-0 relative duration-500 ' +
        'relative hover:z-1  ' + className + (link ? ' hover:z-10 cursor-pointer' : ' cursor-default');

    return <>
        <Link to={link} className={linkClassName}>
            <div
                className={'aspect-video h-full'}>
                <div
                    className={'w-full h-full rounded-lg shadow-xl overflow-hidden absolute top-0 bg-black/5 left-0 flex items-center justify-center'}>
                    {project.photo ?
                        <img src={project.photo?.wide_md ?? '/placeholder.svg'}
                             className={(link ? 'group-hover:scale-[1.1]' : '') + ' duration-200 ease-in absolute flex items-center justify-center text-black ' +
                                 'top-0 left-0 w-full bg-white dark:bg-black h-neutral-800 h-full object-cover '}
                             style={{textIndent: -999999}}
                             alt={project?.title ?? '-'}/>
                        : <img src={'/static/placeholder.svg'} className={'w-10 mx-auto dark:invert opacity-[5%]'}
                               alt={project?.title ?? '-'}/>}
                </div>
            </div>

            <div
                className={'flex flex-wrap duration-300 px-5 left-0 absolute bottom-0 z-10 text-sm mix-blend-exclusion drop-shadow py-2'}>
                <span className={'w-full drop-shadow-lg transition-all block'}>
                    {project.title}
                </span>
            </div>
        </Link>
    </>
}


export default function SectionOurWork() {
    const [workSectionTitle, setWorkSectionTitle] = useState('Our Work')
    const [workSectionDescription, setWorkSectionDescription] = useState('');
    const [work, setWork] = useState([]);
    const [loading, setLoading] = useState(false);

    const [scrollY, setScrollY] = useState(window.scrollY);

    const workRef = useRef()

    useEffect(() => {

        // Load work objects
        setLoading(true)
        api.get('/work/front').then(r => {

            if (!r?.data?.data) {
                return
            }

            setWork(r.data?.data)

            if (r.data.title)
                setWorkSectionTitle(r.data.title)

            if (r.data.description)
                setWorkSectionDescription(r.data.description)


        }).finally(() => setLoading(false))

        const updateScrollY = () => {
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', updateScrollY)
        return () => window.removeEventListener('scroll', updateScrollY)
    }, []);

    return <div className={'relative mx-auto container overflows-hidden group/mainContainer 4xl:pt-64'} ref={workRef}>

        <div className={'absolute justify-center flex -top-[30vh] flex-wrap min-h-screen z-0 w-screen left-0'}
             style={{transform: 'translateY(' + (window.scrollY * .4 - window.innerHeight) + 'px)'}}>
            {work.map((w, i) =>
                <ProjectBlock
                    className={'top-0 z-40 scale-[2] blur-ssm opacity-5 select-none w-1/2 sm:w-1/2 md:w-1/2 ease-out lg:w-1/2 3xl:w-1/4 p-0.5 xl:w-1/4'}
                    project={w} i={i}/>)}</div>

        <div className={'w-full z-10'}>
            <div className={'px-3'}>
                <h2 className={'sh ease-out duration-1000'} style={{
                    transform:
                        "translateY(" + Math.max(0, (-((window.scrollY * .4) - workRef.current?.getBoundingClientRect().top - (window.innerHeight * .9)) * .2)) + "px)"

                }}
                    dangerouslySetInnerHTML={{__html: workSectionTitle}}/>

                <div className={'sd -mt-64 -mb-22 h-0 duration-[1200ms] relative z-20 ease-out'}
                     style={{
                         opacity: window.scrollY > (window.innerHeight * 3.12) ? 0 : 1,
                         transform: (window.scrollY < window.innerHeight * 3.9)
                             ? 'translateY(' + Math.max(0, (-((window.scrollY * .01) - workRef.current?.getBoundingClientRect().top * 2 - (window.innerHeight)) * .11)) + 'px)'
                             : '',

                     }}
                     dangerouslySetInnerHTML={{__html: workSectionDescription}}/>

            </div>
            {loading
                ? <div className={'flex items-center justify-center py-64'}>
                    <BarLoader color={'#09e'}/>
                </div>
                : <div
                    className={'mt-44 relative flex items-center content-center flex-wrap md:px-0'}>
                    <div className={'flex max-w-7sxl mx-auto flex-wrap z-30 w-full justify-center'}>{work.map((w, i) =>
                        <div key={w.title}
                             className={'z-10 relative select-none w-1/2 sm:w-1/2 md:w-1/2 duration-[1600ms] ease-out lg:w-1/2 3xl:w-1/4 p-1 xl:w-1/4'}
                             style={{
                                 transform: "translateY(" +
                                     Math.max(0,
                                         Math.min(window.innerHeight, (-((window.scrollY * 6.3) - workRef.current?.getBoundingClientRect()?.top - (window.innerHeight * 20))) / ((i % 2) + 4.7))
                                     )
                                     + "px)"
                             }}>
                            <ProjectBlock project={w} link={w.link} i={i}/>
                        </div>)}
                    </div>
                </div>
            }</div>
    </div>
}
