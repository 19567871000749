import React, {useContext, useEffect, useRef, useState} from 'react';
import {faBars, faFileContract, faHome, faMultiply} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Transition} from "@headlessui/react";
import {faFacebookSquare, faInstagram, faLinkedin, faYoutubeSquare} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";
import defaultTheme from "tailwindcss/defaultTheme";
import {AppContext} from "../context/AppContext";

export default function Navbar() {

    const sidebarRef = useRef(null)
    const topbarRef = useRef(null)
    const navRef = useRef(null)
    const {sidebarHidden, setSidebarHidden} = useContext(AppContext);
    const [sidebarOpened, setSidebarOpened] = useState(false);
    const [navBG, setNavBG] = useState(null);
    const [navSize, setNavSize] = useState(null);
    const NavButton = ({className, title, href, onClick}) => {

        const linkClass = 'duration-1000 cursor-pointer dark:hover:bg-sky-500 dark:hover:text-white ' +
            'hover:bg-orange-600 hover:text-black inline-block hover:!pb-[21px] hover:pt-[83px] duration-500 ' +
            'z-10 px-5 hover:-mt-16 text-center group relative '

        return <>
            {href ? <Link to={href} className={linkClass} onClick={onClick}>
                <span className={'' + (className ? ' ' + className : '')}>{title}</span>
            </Link> : <span className={linkClass} onClick={onClick}>{title}</span>}
        </>
    }

    useEffect(() => {

        const handleMousemove = () => {
            clearTimeout(navRef.current);
            setSidebarHidden(false);
            navRef.current = setTimeout(() => {
                setSidebarHidden(true);
            }, 30000);

            if (window.scrollY >= window.innerHeight + 500) {
                setNavBG('4xl:bg-transparent 4xl:mix-blend-difference bg-black')
            } else {
                setNavBG('')
            }


            if (window.scrollY >= 2) {
                setNavSize('text-[.99em] font-thin')
            } else {
                setNavSize('!text-2xl')
            }
        };

        window.addEventListener('mousemove', handleMousemove);
        window.addEventListener('scroll', handleMousemove);
        window.addEventListener('click', handleMousemove);
        window.addEventListener('keydown', handleMousemove);

        const checkScreen = () => {
            if (window.innerWidth > parseInt(defaultTheme.screens.lg)) {
                setSidebarOpened(false)
            }

        }
        window.addEventListener('click', handleWindowOnClickEvent);
        window.addEventListener('resize', checkScreen);

        return () => {
            window.removeEventListener('resize', checkScreen)
            window.removeEventListener('click', handleWindowOnClickEvent);
            window.removeEventListener('mousemove', handleMousemove);
            window.removeEventListener('scroll', handleMousemove);
            window.removeEventListener('click', handleMousemove);
            window.removeEventListener('keydown', handleMousemove);
        }

    }, []);

    const handleWindowOnClickEvent = (e) => {

        if (
            sidebarRef.current && topbarRef.current &&
            (!sidebarRef.current?.contains(e.target) && !topbarRef?.current?.contains(e.target))
        ) {
            setSidebarOpened(false)
        }

    }

    const SidebarMenuLink = ({target, className, text, href}) =>
        <>
            <Link
                className={'hover:scale-110 mx-auto py-2 block font-light justify-center ' +
                    'dark:hover:bg-blue-900 dark:text-neutral-200 dark:hover:text-white ' +
                    'hover:bg-sky-700 hover:text-white duration-300 hover:rounded-sm w-full text-center hover:fonts-black' +
                    (className ? ' ' + className : '')}
                to={href ?? '#'} onClick={() =>
                setSidebarOpened(false) || document.getElementById(target).scrollIntoView({behavior: 'smooth'})}>
                {text}
            </Link>
        </>

    return <div ref={topbarRef}>
        <div
            className={'fixed top-1 left-0 w-screen px-4 z-50 text-white '
                + (sidebarHidden ? 'duration-2000 opacity-5' : ' duration-200 opacity-100')
                + ' ' + navBG}>
            <nav className={'flex items-center group justify-between'}>
                <div className={'flex items-center justify-start h-20 pb-2 px-5'} ref={navRef}>
                    <Link to={'/'}
                          className={'relative z-[101] invert font-black text-2xl select-none cursor-pointer'}
                          onClick={() => window.scrollTo({top: 0, behavior: 'smooth'}) || setSidebarOpened(false)}>
                        <img src={'/logo.svg'}
                             className={'block w-28 lg:w-32 xl:w-36 invert mt-0.5 xl:mt-1 4xl:mt-3 duration-1000 py-3 relative z-50'}
                             alt={'AGA ADK'}/>
                    </Link>
                </div>

                <div className={'relative hidden select-none xl:flex items-center mt-1 duration-300 text-2xl font-light ' + (navSize)}>
                    <NavButton icon={faFileContract} title={'About'}
                               href={'/#about'}
                               onClick={() => document.getElementById('about').scrollIntoView({behavior: 'smooth', block: 'end'})}
                    />
                    <NavButton icon={faFileContract} title={'Our Work'}
                               href={'/#work'}
                               onClick={() => document.getElementById('work').scrollIntoView({behavior: 'smooth', block:'start'})}/>
                    <NavButton icon={faFileContract} title={'Clients'}
                               href={'/#clients'}
                               onClick={() => document.getElementById('clients').scrollIntoView({behavior: 'smooth',block:'center'})}/>
                    <NavButton icon={faFileContract} title={'News'}
                               href={'/#news'}
                               onClick={() => document.getElementById('news').scrollIntoView({behavior: 'smooth'})}/>
                    <NavButton icon={faFileContract} title={'Career'}
                               href={'/#careers'}
                               onClick={() => document.getElementById('careers').scrollIntoView({behavior: 'smooth'})}/>
                    <NavButton icon={faFileContract} title={'People'}
                               href={'/#people'}
                               onClick={() => document.getElementById('people').scrollIntoView({behavior: 'smooth'})}/>
                    <NavButton icon={faFileContract} title={'Contact Us'}
                               href={'/#contact'}
                               onClick={() => document.getElementById('contact').scrollIntoView({behavior: 'smooth'})}/>

                </div>

            </nav>
        </div>
        <FontAwesomeIcon
            className={'fixed xl:hidden focus-within:!outline-0 focus:!ring-none focus-visible:!ring-0 ring-0 active:!border-1 ' +
                ' z-[103] mr-5 mt-1 hover:scale-125 aspect-square' +
                ' duration-100 ring cursor-pointer ' +
                (sidebarOpened
                    ? ' top-3 right-0 -rotate-90 text-xl p-4 rounded-full shadow-xl ring-2 ring-white '
                    : ' top-3 right-1 ring-transparent sm:text-3xl lg:text-3xl xl:text-3xl text-2xl')}
            tabIndex={1}
            onClick={() => setSidebarOpened(!sidebarOpened)}
            icon={sidebarOpened ? faMultiply : faBars}/>
        <Transition show={!!sidebarOpened}
                    enter={'duration-200 '}
                    enterFrom={'opacity-0 -mt-[100vh]'}
                    enterTo={'opacity-100 mt-0'}
                    leave={'duration-200'}
                    leaveFrom={'-mr-[100vw] -top-0 opacity-100'}
                    beforeEnter={() => setSidebarOpened(true)}
                    beforeLeave={() => setSidebarOpened(false)}
                    leaveTo={'-mr-[100vw] opacity-0'}>
            <div
                className={'fixed py-32 z-[100] font-semibold flex items-center top-0 right-0 bg-black/90 backdrop-blur-2xl ' +
                    'dark:text-white sm:max-w-sm max-w-lg w-full h-screen'} ref={sidebarRef}>
                <div className={'text-sm h-screen place-content-around w-screen'}>
                    <div className={'w-min mx-auto text-xl overflow-hidden flex flex-wrap h-full contents-around'}>
                        <div className={'w-full content-end'}>
                            <div className={'duration-500 delay-200' + (sidebarOpened ? ' scale-150 opacity-100 ' : ' opacity-0')}>
                                <SidebarMenuLink className={'mb-12'}
                                                 text={<FontAwesomeIcon icon={faHome}/>}
                                                 target={'main'}/>
                            </div>
                            <SidebarMenuLink text={'About'} target={'about'} href={'/#about'}/>
                            <SidebarMenuLink text={'Work'} target={'work'} href={'/#work'}/>
                            <SidebarMenuLink text={'Clients'} target={'clients'} href={'/#clients'}/>
                            <SidebarMenuLink text={'News'} target={'news'} href={'/#news'}/>
                            <SidebarMenuLink text={'People'} target={'people'} href={'/#people'}/>
                            <SidebarMenuLink text={'Career'} target={'careers'} href={'/#careers'}/>
                            <SidebarMenuLink text={'Contact'} target={'contact'} href={'/#contact'}/>
                        </div>

                        <div className={'w-full text-xl flex items-center justify-center'}>
                            <FontAwesomeIcon className={'hover:scale-110 m-5 transition-all cursor-pointer'}
                                             icon={faFacebookSquare}/>
                            <FontAwesomeIcon className={'hover:scale-110 m-5 transition-all cursor-pointer'}
                                             icon={faInstagram}/>
                            <FontAwesomeIcon className={'hover:scale-110 m-5 transition-all cursor-pointer'}
                                             icon={faLinkedin}/>
                            <FontAwesomeIcon className={'hover:scale-110 m-5 transition-all cursor-pointer'}
                                             icon={faYoutubeSquare}/>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
}
