import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {api} from "../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

export default function SectionNews() {

    const [isLoading, setIsLoading] = useState(true);
    const [loadedNews, setLoadedNews] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        api.get('/news/get').then(response => {
            setLoadedNews(Object.values(response.data.data))
        }).catch(error => {

        }).finally(() => {
            setIsLoading(false)
        })
    }, []);

    const NewsBlock = React.memo(({article, index}) =>
        <Link to={'/news/' + article.slug}
              className={'w-full bg-black transition-all text-white hover:text-black group h-full hover:bg-white/80 flex flex-wrap items-center p-10'}>
            <div className={'w-full h-32 2xl:w-3/12 bg-white rounded 2xl:h-full aspect-square'}>
                <img className={'h-full object-cover w-full rounded'} src={'/65ae6fcee5d1a.png'} alt={article?.title ?? '-'}/>
            </div>
            <div className={'w-full 2xl:w-9/12 pt-5 2xl:pt-0 2xl:px-10'}>
                <h2 className={'font-bold text-2xl'}>{article.title}</h2>
                <p>{article.short_description}</p>
            </div>
        </Link>, [])

    const NewsBlocks = React.memo(() =>
        <div className={'my-10 flex flex-wrap w-full'}>
            {loadedNews.map((n, i) =>
                <div className={'group p-2 first:w-full w-full sm:w-1/2 xl:w-1/3 relative '}>
                    <NewsBlock article={n} index={i}/>
                </div>)
            }
        </div>)

    return <div className={''}>
        <>
            <div className={'my-20'}>
                <h2 className={'sh !text-black text-start sh px-5'}>News <span className={'font-light'}>articles</span></h2>
                <hr className={'max-w-sm my-10 border-black dark:border-sky-600 mx-5'}/>
            </div>

            {isLoading
                ?
                <div className={'w-full my-20 animate-pulse h-full flex items-center justify-center space-x-2'}>
                    <FontAwesomeIcon icon={faSpinner} spin={true}/> <span>loading <strong>articles</strong>...</span>
                </div>
                : <div className={'container mx-auto px-3'}>
                    <NewsBlocks/>
                </div>}
        </>
    </div>
}