import React from 'react';
import PageContainer from "../../components/PageContainer";

export default function CareersIndex() {
    return <PageContainer title={'Careers - AGA-ADK'}
                          helmet={{title: 'Careers'}}
                          className={'min-h-screen'}>
        <p>
            A list of currently open positions at AGA-ADK can be found below
        </p>

    </PageContainer>
}