import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import NoMatch from "./pages/NoMatch";
import {AppContext} from "./context/AppContext";
import Navbar from "./components/Navbar";
import CareersIndex from "./pages/Career/CareersIndex";
import WorkIndex from "./pages/Work/WorkIndex";
import WorkSingle from "./pages/Work/WorkSingle";
import CareersSingle from "./pages/Career/CareerSingle";
import axios from "axios";
import NewsSingle from "./pages/News/NewsSingle";

const root = ReactDOM.createRoot(document.getElementById('root'));

export const api = axios.create()
api.defaults.baseURL = process.env.REACT_APP_API_URL + '/api'
api.interceptors.response.use(response => response, error => {
    console.error('Err ' + error)
})

const Wrapper = ({children}) => {
    const [sidebarHidden, setSidebarHidden] = useState(false)
    const [showNavbar, setShowNavbar] = useState(true);

    const fn = {
        sidebarHidden: sidebarHidden,
        setSidebarHidden: setSidebarHidden,
        showNavbar: showNavbar,
        setShowNavbar: setShowNavbar,
    }

    return <>
        <AppContext.Provider value={{...fn}}>
            <div className={'m-3 rounded-xl overflow-hidden bg-black'} id={'main'}>
                <Navbar/>
                <div className={'pt-36'}>
                    <div>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </AppContext.Provider>
    </>
}
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppContext.Provider value={{}}>
                <Routes>
                    <Route path={'/'} element={<Wrapper/>}>
                        <Route index element={<App/>}/>
                        <Route path={'work'} element={<WorkIndex/>}/>
                        <Route path={'work/:id'} element={<WorkSingle/>}/>
                        <Route path={'news/:slug'} element={<NewsSingle/>}/>
                        <Route path={'careers'} element={<CareersIndex/>}/>
                        <Route path={'careers/:id'} element={<CareersSingle/>}/>
                        <Route path="*" element={<NoMatch/>}/>
                    </Route>
                </Routes>
            </AppContext.Provider>
        </BrowserRouter>
    </React.StrictMode>
);
reportWebVitals();
