import React from 'react';

function TextArea({id, label, name, rows = 3, onChange, placeholder, defaultValue,disabled}) {
    return <div className={'ic'}>
        {label ? <label htmlFor={id}>{label}</label> : <></>}
        <textarea
            id={id}
            className={'i'}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            rows={rows}
            name={name}
            defaultValue={defaultValue}/>
    </div>
}

export default TextArea;
